import React, { ReactElement } from 'react';
import { NotFound } from '../pages/errors/NotFound';
import { NafCenterArticle } from '../pages/nafCenter/center';

export const NafCenterPageRoute = ({
  match: {
    params: { centerSlug },
  },
}: {
  match: { params: { centerSlug: string } };
}): ReactElement => {
  if (!centerSlug) {
    return <NotFound />;
  }
  return <NafCenterArticle centerSlug={centerSlug} />;
};
