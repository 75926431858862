import React from 'react';

interface Props {
  id?: string;
  title: string | null;
  openingTime: string | null;
  closingTime: string | null;
  openingTimeFriday?: string | null;
  closingTimeFriday?: string | null;
  address: string | null;
  city: string | null;
  coordinates: {
    longitude: string;
    latitude: string;
  } | null;
}

const SchemaCenter = ({
  id = '',
  title,
  openingTime,
  closingTime,
  openingTimeFriday,
  closingTimeFriday,
  address,
  city,
  coordinates,
}: Props) => {
  const structuredData = `{
        "@context": "http://schema.org",
		    "@type": "AutoRepair",
        "name": "${title}",
        "openingHours": ["Mo-To ${openingTime}-${closingTime}", "Fr ${openingTimeFriday}-${closingTimeFriday}"],
        "location": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${address}",
              "addressRegion": "${city}"
            },
            "latitude": "${coordinates?.latitude}",
            "longitude": "${coordinates?.longitude}"
          }
    }`;

  return (
    // eslint-disable-next-line react/no-danger
    <script type="application/ld+json" key={`centerJSON-${id}`} dangerouslySetInnerHTML={{ __html: structuredData }} />
  );
};

export default SchemaCenter;
